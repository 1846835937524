import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useIntl } from 'react-intl';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  MenuItem,
  Modal,
  Select,
  Typography
} from '@material-ui/core';
import { schemaPrice } from './validations';
import FormikFieldIcon from 'cms/components/Formik/FomikFieldIcon';
import messages from '../messages';
import { FaTimesCircle } from 'react-icons/fa';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectCategories } from '../redux/selectors';
import { getCategories } from '../redux/actions';
import FormikField from 'components/FormikField';

const ModalBox = styled(Box)`
  background: white;
  width: 50%;
  margin: 0 auto;
  top: 30%;
  position: absolute;
  left: 30%;
  padding: 20px;
  display: flex;
  form {
    flex-direction: column;
    width: 50%;
    .input_holder {
      margin-bottom: 15px;
      width: 250px;
    }
    button {
      margin-top: 15px;
      width: 250px;
      margin-left: 0;
    }
  }
`;
export default function Create({ onSubmit }) {
  const { formatMessage } = useIntl();
  const [showModal, toggleShowModal] = useState(false);

  const categories = useSelector(makeSelectCategories());

  const handleSubmit = (values, { setErrors, resetForm }) => {
    const payload = {
      name: values.name,
      categories: { ...values }
    };

    delete payload.categories?.name;

    onSubmit(payload, setErrors, resetForm);
    toggleShowModal(false);
  };

  return (
    <>
      <Button
        style={{
          position: 'absolute',
          right: '35px'
        }}
        className="button"
        onClick={() => toggleShowModal(true)}
      >
        Dodaj API klijenta
      </Button>
      <Modal
        open={showModal}
        onClose={() => toggleShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {showModal ? (
          <div className="api_client_edit">
            <div>
              <Button
                className="close_button"
                onClick={() => toggleShowModal(false)}
              >
                X
              </Button>
              <p className="title">Dodaj novog klijenta</p>
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { setErrors, resetForm }) => {
                  handleSubmit({ ...values }, { setErrors, resetForm });
                }}
                enableReinitialize={true}
              >
                {({ errors, touched, values, setFieldValue }) => {
                  return (
                    <Form className="api_form">
                      <FormikField
                        name="name"
                        errors={errors}
                        touched={touched}
                        type="text"
                        placeholder="Ime"
                        className="name"
                      />
                      <div style={{ width: '100%', maxHeight: '50vh' }}>
                        {categories.map((cat, index) => {
                          return (
                            <div className="api_field">
                              <span>%</span>
                              <FormikField
                                name={cat.id}
                                errors={errors}
                                touched={touched}
                                type="number"
                                className="api_percentage"
                              />
                              <p>{cat.name}</p>
                            </div>
                          );
                        })}

                        <Button className="button" type="submit">
                          Dodaj
                        </Button><br/>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
}

Create.propTypes = {
  onSubmit: PropTypes.func
};

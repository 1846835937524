import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CoreTooltip from '@material-ui/core/Tooltip';

const Tooltip = ({ title, children, ...rest }) => {
  const LightTooltip = withStyles(() => ({
    tooltip: {
      fontSize: 14,
      padding: !title && 0
    }
  }))(CoreTooltip);

  return (
    <LightTooltip title={title} {...rest}>
      <div className="tooltip_wrap">{children}</div>
    </LightTooltip>
  );
};

export default Tooltip;

/* eslint-disable react-hooks/exhaustive-deps */
import Wrap from 'cms/components/Wrap';
import React, { useState, useEffect, useCallback } from 'react';

import { Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { paginate } from 'helpers/paginate';

import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';

import reducer from './redux/reducer';
import saga from './redux/saga';
import { makeSelectCategories, makeSelectData } from './redux/selectors';
import {
  getCategories,
  getData,
  addData,
  updateData,
  setActive
} from './redux/actions';
import Create from './Forms/Create';
import Table from './Table';
const key = 'apiClient';

const ApiClient = () => {
  const [current_page, setCurrentPage] = useState(1);
  const [per_page, setPerPage] = useState(20);

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const data = useSelector(makeSelectData());
  const categories = useSelector(makeSelectCategories());

  const items = paginate(data, current_page, per_page);

  const meta = { total: data && data.length, per_page, current_page };

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getData());
  }, []);

  const handleCreate = useCallback((...args) => dispatch(addData(...args)), [
    dispatch
  ]);

  const handleUpdate = useCallback((...args) => dispatch(updateData(...args)), [
    dispatch
  ]);

  const handleActive = useCallback((...args) => dispatch(setActive(...args)), [
    dispatch
  ]);

  return (
    <Wrap
      classes="catalog_stickers_screen"
      isInline={true}
      data={data}
      meta={meta}
      type="cms"
      params={per_page}
      setParams={setPerPage}
      setCurrentPage={setCurrentPage}
      skeleton={<Skeleton className="inline" />}
      headerLeftContent={
        <Create onSubmit={handleCreate} categories={categories} />
      }
      tableContent={
        <Table
          data={items}
          handleUpdate={handleUpdate}
          handleChangeActive={handleActive}
          categories={categories}
        />
      }
    />
  );
};

export default ApiClient;

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { DeleteOutlined, Edit } from '@material-ui/icons';

import { makeSelectUser } from 'containers/App/selectors';
import TableCell from './TableCell';
import IsFormVisible from 'helpers/IsFormVisible';

const ActionIcons = ({
  link,
  handleUpdate,
  handleDelete,
  updateRole,
  deleteRole,
  newTab = false
}) => {
  const { push } = useHistory();
  const onUpdate = () => {
    if (link && newTab) {
      const win = window.open(link, '_blank');
      return win.focus();
    }
    if (link) return push(link);
    return handleUpdate();
  };
  const user = useSelector(makeSelectUser());
  return (
    <TableCell>
      <IsFormVisible user={user} role={updateRole}>
        <IconButton className="icon_button edit" onClick={onUpdate}>
          <Edit />
        </IconButton>
      </IsFormVisible>
      <IsFormVisible user={user} role={deleteRole}>
        <IconButton className="icon_button delete" onClick={handleDelete}>
          <DeleteOutlined />
        </IconButton>
      </IsFormVisible>
    </TableCell>
  );
};

export default ActionIcons;
